import React, { useState, useEffect } from "react"
import { useLazyQuery, useMutation } from '@apollo/client';
import { Field } from 'react-final-form'
import Wizard from '../components/Wizard'
import logoImage from "../images/logo.png";
import SURVEY_FORM_DATA from "../queries/surveyForm"
import SURVEY_DETAIL from "../queries/savedSurveyDetail"
import SAVE_FORM_MUTATION from "../queries/saveFormMutation"
import VERIFY_AC_USER_MUTATION from "../queries/verifyUserMutation"

const required = value => (value ? undefined : 'Required')

const SurveyForm = ({location}) => {

    console.log('First : ' +new Date().toLocaleString())

    const [userVerified, setUserVerified] = useState(null)
    const [userData, setVerifiedUserData] = useState(null)
    const [primaryInterest, setPrimaryInterest] = useState("")
    const [formData, setFormData] = useState(null)
    const [status, setStatus] = useState('pending')
   
    // Get the token from URL
    const params = new URLSearchParams(location.search);
    const token = params.get("tkn");

    // Saved Survey Details
    const [getData, { data: savedSurveyData, loading: loadingSavedData }] =useLazyQuery(SURVEY_DETAIL, {
        onCompleted: ( savedSurveyData ) => {
            let formDataTemp = []
            //console.log('Get Saved Data')
            {savedSurveyData?.surveyDetail?.answers?.nodes.map((quesans, index)=>(
                formDataTemp[quesans.question_tag]=quesans.answer
            ))}
    
            if(savedSurveyData?.surveyDetail?.status)
                setStatus(savedSurveyData.surveyDetail.status)
               // status = savedSurveyData?.surveyDetail?.status;
            
            //console.log(formData)
            setFormData(formDataTemp)

            if(formDataTemp["primary_interest"]!==undefined)
                setPrimaryInterest(formDataTemp["primary_interest"])
        },
        onError: () => {
            //console.log('Get Saved Data Failed')
        }
    });

    // Survey Form Ques/Ans
    const [getFormData,  { data: surveyFormData, loading: loadingFormData }] = useLazyQuery(SURVEY_FORM_DATA);

    // Save Data in WP db 
    const [saveSurvey] = useMutation(SAVE_FORM_MUTATION, {
        onCompleted: ( submittedData ) => {
            //console.log('Form Submitted')
            return true;
        },
        onError: () => {
            //console.log('Form Submission')
            return false;
        }
    });

    // Verify User
    const [verifyACUser, {loading: loadingAPI}] = useMutation(VERIFY_AC_USER_MUTATION, {
        onCompleted: ( verifiedData ) => {
         //   console.log('api complete : ' + new Date().toLocaleString())
          let tempUserdata = JSON.parse(verifiedData.verifyACUser.data);
          //console.log(tempUserdata.result)

            if(tempUserdata.result.userverified===1) {
                setUserVerified(1)
                setVerifiedUserData(tempUserdata.result)
                getData({variables: { token: token }})
                getFormData({variables: { id: "cG9zdDo1NzA5" }}) //cG9zdDo1NzA5 / cG9zdDo1MDQ=
            } else {
                setUserVerified(0)
            }
        },
        onError: () => {
          setUserVerified(0);
          //console.log('error')
        }
    });

    // Validate token using AC through Wp
    useEffect(() => {
        //console.log('call api : ' + new Date().toLocaleString())
        if(userVerified===null && token!==null)
            verifyACUser({variables: {"clientMutationId": "verify", "token": token}});

        /*if(token!==null) {
            getData({variables: { token: token }})
            getFormData({variables: { id: "cG9zdDo1NzA5" }}) //cG9zdDo1NzA5 / cG9zdDo1MDQ=
        }*/
    },[token])

    // Validate Form (Email, Text, WhatsApp)
    const validateForm = values => {

        if (values.Email === undefined && values.Text === undefined && values.WhatsApp === undefined) {
          return false;
        } else {
            return true;
        }
    }

   /* console.log('surveyFormData : ' +new Date().toLocaleString())

    console.log(surveyFormData)

    console.log('savedSurveyData : ' +new Date().toLocaleString())

    console.log(savedSurveyData)
*/
    const handlePrimaryInterestDisable = (values) => {
        if(values.primary_interest){
            setPrimaryInterest(values.primary_interest)
        }
    }

    const onSubmit = async (values, isLastPage) => {

        if(isLastPage) {
            //console.log('last page')
            const isValid = validateForm(values)

            if(!isValid) {
                //console.log('not valid')
                return { "status": false, error: "Please select atleast one option" }
            }
        }

        //console.log('main : onSubmit')
        handlePrimaryInterestDisable(values)
        var surveydata = {};
        surveydata.clientMutationId = 'survey';
        surveydata.token = token;
        surveydata.answers = JSON.stringify(values);
        surveydata.userdata = JSON.stringify(userData);

        var updateAC = isLastPage ? '1' : '0'
        surveydata.updateacuser = updateAC

        const isFormSubmitted = await saveSurvey({variables: surveydata});

        if(isFormSubmitted?.data?.saveSurvey?.success===true)
            return { "status": true, error: "" }
        else   
            return { "status": false, error: "Unable to upate" }
    }

    // Display Error Messages 
    //console.log(userVerified + '-' + status)
    let message = '';
    if(token===undefined)
        message = 'Invalid Token.'
    else if(userVerified===0)
        message = 'Invalid Token.'
    else if(userVerified===1 && status==='complete')
        message = 'You already submitted Survey.'
    else if(status !== 'complete' && userVerified===1 && surveyFormData===null)
        message = 'Invalid URL.';

        console.log('Last : ' +new Date().toLocaleString())

    return (
        <section>
            <main>
                {(loadingSavedData || loadingFormData || loadingAPI) &&
                    <div className="error-message">Please Wait..</div>}
                {message!=='' &&
                    <div>
                        <div className="logo_main">
                            <img src={logoImage} alt="Logo"/>
                        </div>
                        <div className="error-message">
                            {message}
                        </div>
                    </div>
                }
                {status!=='complete' && userVerified===1 && surveyFormData && surveyFormData.survey && surveyFormData.survey.surveyForm && (
                    <div className="all_pages_main second_page">
                        <Wizard 
                            page = {savedSurveyData?.surveyDetail?.answered_questions ? savedSurveyData.surveyDetail.answered_questions : 0}
                            initialValues={formData}
                            onSubmit={onSubmit}
                            >
                            <Wizard.Page type="Custom" nextText={surveyFormData.survey.surveyForm?.startPage.startButtonText} key='0'>
                                <div className="all_pages_main first_page">
                                    <div className="logo_main">
                                        <img src={logoImage} alt="Logo"/>
                                    </div>
                                    {surveyFormData.survey.surveyForm?.startPage?.surveyTopHeading && 
                                    <h2 className="page_desc" dangerouslySetInnerHTML={{__html: surveyFormData.survey.surveyForm?.startPage?.surveyTopHeading}}></h2>}
                                    <div className="first_screen_img"><img src={surveyFormData.survey.surveyForm?.startPage?.bannerImage?.sourceUrl} alt="" /></div>
                                    <h2 className="page_title">{surveyFormData.survey.surveyForm?.startPage?.surveyHeading}</h2>
                                    <p className="page_desc">{surveyFormData.survey.surveyForm?.startPage?.surveySubHeading}</p>
                                </div>
                            </Wizard.Page>
                            {surveyFormData.survey.surveyForm.questionAnswer.map((quesAns, index) => (
                            <Wizard.Page  type={quesAns.answerType} nextText={quesAns?.customContent?.customButtonText} key={index} >
                                {quesAns.answerType==='Custom' && 
                                    <div className="all_pages_main first_page">
                                        {quesAns?.bannerImage?.sourceUrl!==null && <div className="first_screen_img"><img src={quesAns?.bannerImage?.sourceUrl} alt="" /></div>}
                                        <h2 className="page_title">{quesAns.customContent.customHeading}</h2>
                                        <p className="page_desc">{quesAns.customContent.customSubheading}</p>
                                    </div>
                                }
                                {quesAns.answerType!=='Custom' &&  quesAns.answerType!=='Communication' &&
                                    <div className={quesAns.styleClass}>
                                        <h2 className="page_title">{quesAns.question}</h2>
                                        {quesAns.questionSubHeading!==null && 
                                            <p className="page_desc">{quesAns.questionSubHeading}</p>}
                                        {quesAns.bannerImage!==null && 
                                            <div className="first_screen_img intersted_screen_img"><img src={quesAns?.bannerImage?.sourceUrl} alt="" />
                                            </div>}
                                        {quesAns.questionInfo!=null &&
                                            <p className="page_desc">{quesAns.questionInfo}</p>
                                        }
                                        <ul className="second_listing">
                                        {quesAns.answers!=null && quesAns.answers.map((ansOption, index) => (
                                            <li className={(primaryInterest === ansOption.text) ? 'second_item primary-parent' : 'second_item'} key={index}>
                                            <label key={index}>
                                                {quesAns.answerType==='Single Select' && 
                                                <Field name={quesAns.questionTag} component="input" type="radio" className="input_control" value={ansOption.text} validate={required}  />}
                                                {quesAns.answerType==='Multi Select' && 
                                                <Field name={quesAns.questionTag} component="input" type="checkbox" value={ansOption.text} validate={required} disabled={quesAns.questionTag === "other_interest" && primaryInterest === ansOption.text}/>}
                                                <div className="listing_img_txt">
                                                    {ansOption.imageType==='Step' && 
                                                    <div className="img_change_count">{index+1}</div>}
                                                    {ansOption.imageType==='Image' && ansOption.image!=null && (
                                                        <>
                                                        {quesAns.questionTag === "other_interest" && primaryInterest === ansOption.text && (
                                                            <span className="primary_interest_disabled">PRIMARY</span>
                                                        )}
                                                        <img src={ansOption?.image?.sourceUrl} alt=""/>
                                                        </>
                                                    )}
                                                    <div className="listing_content">
                                                        <span>{ansOption.text}</span>
                                                        {ansOption.subText!==null && <p>{ansOption?.subText}</p>}
                                                    </div>
                                                </div>
                                            </label>
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                }
                                {quesAns.answerType==='Communication' && 
                                    <div className={quesAns.styleClass}>
                                        <h2 className="page_title">{quesAns.communication.communicationTypeHeading}</h2>
                                        <p className="page_desc">{quesAns.communication.communicationTypeSubheading}</p>
                                        <div className="first_screen_img should_talk_img"><img src={quesAns?.bannerImage?.sourceUrl} alt="" /></div>
                                        <div className="should_talk_field">
                                            {quesAns.communication.communicationType.map((comm, index) => (
                                                <label className={comm.type} key={index}> 
                                                    <span>{comm.type}</span>
                                                    {comm.enableTextbox===true && comm.type==='Email' &&
                                                    <Field name={comm.type} placeholder={comm.type} component="input" type="email" />}
                                                    {comm.enableTextbox===true && comm.type!=='Email' &&
                                                    <Field name={comm.type} placeholder={comm.type} component="input" type="text" />}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </Wizard.Page>
                            )) }
                            <Wizard.Page>
                            <div className="congratulations_main">
                                <h2 className="page_title">{surveyFormData.survey.surveyForm.completion.completionHeading}</h2>
                                <p className="page_desc">{surveyFormData.survey.surveyForm.completion.completionMessage}</p>
                                <div className="first_screen_img congratulations_screen_img">
                                    <img src={surveyFormData.survey.surveyForm.completion?.completionImage?.sourceUrl} alt="" />
                                </div>
                            </div>
                            </Wizard.Page>
                        </Wizard>
                        
                    </div>  

                )}
            </main>
      </section>
  )
}

export default SurveyForm